import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { requests } from '../Requests';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

function Person() {
  const [person, setPerson] = useState({});
  const [movies, setMovies] = useState([]);
	const personid = useParams().personid;
	const navigate = useNavigate();

	const handleBackClick = () => {
    navigate(-1);  // This will navigate back
  };

  const slideLeft = (sliderName, event) => {
    event.preventDefault();
    var slider = document.getElementById(sliderName);
    slider.scrollLeft = slider.scrollLeft - 500;
};

const slideRight = (sliderName, event) => {
    event.preventDefault();
    var slider = document.getElementById(sliderName);
    slider.scrollLeft = slider.scrollLeft + 500;
};


  useEffect(() => {
    async function fetchData() {
			// console.log("Person ID after fetchData: ", personid);
			const detailURL = requests.requestPerson(personid);
			// console.log("Person ID: ", detailURL);
      
      await api.get(detailURL)
        .then((response) => {
          setPerson(response.data);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          // Handle other errors differently, if needed
        });
      ;

      // setPerson(personResponse.data);
			// console.log("Person: ", personResponse.data);

			const movieCreditsURL = requests.requestPersonMovieCredits(personid);

      await api.get(movieCreditsURL)
        .then((response) => {
          setMovies(response.data.cast);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          // Handle other errors differently, if needed
        }); 
      ;
      // setMovies(movieCreditsResponse.data.cast);
			// console.log("Movies: ", movieCreditsResponse.data.cast);
    }
    fetchData();
  }, [personid]);

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const ageDifference = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifference);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

	const posterDivWidth = 128;  
  const spacing = 16;  
  const totalWidth = movies.length * posterDivWidth + (movies.length - 1) * spacing;
  const containerWidth = 0.8 * window.innerWidth; // Assuming 80% width based on your w-[80%] class
  const arrowWidth = 42;  // Since you've set the size of the arrow to 40
  const additionalSpacing = 10;  // Additional 10px to give it some space from the last actor div
  const offset = 2 * arrowWidth + additionalSpacing;
	const rightPosition = Math.max(containerWidth - totalWidth - offset, 0);

  return (
		<>
        <div className="pt-4 md:pt-0 lg:pt-0 pb-4 md:pb-0 lg:pb-0">
          <button className="rounded cursor-pointer border text-white border-gray-300 py-2 px-5 ml-2 md:ml-10 lg:ml-10" onClick={handleBackClick}>Back</button>
        </div>
		{/* <div className="relative flex flex-col md:flex-row p-4 text-white"> */}
		<div className="flex flex-wrap md:flex-nowrap p-4 text-white lg:w-[80vw]">

      {/* Left Column */}
      {/* <div className="md:w-1/6 ml-8 md:ml-8 lg:ml-6 mr-8"> */}
			<div className="pr-4 pb-8 md:w-1/6 md:p-4 mt-4 md:mt-0">
        {person?.profile_path ? (
            <img src={requests?.requestProfileImg(person?.profile_path)} alt={person?.name} className="w-185 h-auto object-cover rounded" />
        ) : person?.gender === 2 ? (
            <img className='w-185 h-auto object-cover rounded' src="/user-grey.svg" alt="Male Placeholder" />
        ) : person?.gender === 1 ? (
            <img className='w-185 h-auto object-cover rounded' src="/user-female-grey.svg" alt="Female Placeholder" />
        ) : (
            <img className='w-185 h-auto object-cover rounded' src="/user-grey.svg" alt="Default Placeholder" />
        )}
        <h2 className="text-xl mt-4">Personal Info</h2>
        <h3 className="text-yellow-400 mt-2 text-sm">Known For</h3>
				<p className="text-xs">{person?.known_for_department}</p>
        <h3 className="text-yellow-400 mt-2 text-sm">Known Credits</h3>
				<p className="text-xs">{movies?.length}</p>
        <h3 className="text-yellow-400 mt-2 text-sm">Gender</h3>
				<p className="text-xs">{person?.gender === 1 ? 'Female' : 'Male'}</p>
        <h3 className="text-yellow-400 mt-2 text-sm">Birthday</h3>
				<p className="text-xs">{person?.birthday} ({calculateAge(person.birthday)} years old)</p>
        <h3 className="text-yellow-400 mt-2 text-sm">Place of Birth</h3>
				<p className="text-xs">{person?.place_of_birth}</p>
      </div>

      {/* Right Column */}
      {/* <div className="md:w-3/6 overflow-hidden pr-4"> */}
      <div className="pl-0 md:pl-4 w-full md:w-5/6 pt-4">
        <h1 className="text-3xl">{person.name}</h1>
        <h2 className="text-2xl mt-4">Biography</h2>
        <p className="mt-2">{person.biography}</p>

				<h2 className="text-2xl mt-4">Known For</h2>
				<div className='mt-9 ml-0 w-[90%] lg:w-[95%] md:w-[80%] sm:w-[90%] mr-20 relative flex items-center group'>
					<MdChevronLeft
            onClick={(event) => slideLeft('actorMovies', event)}
            className='bg-white text-black left-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
            size={40}
          />
          <div id="actorMovies" className="flex overflow-x-scroll scrollbar-hide scroll-smooth relative max-h-[300px]">
              {movies.map((movie, id) => {
                  // console.log(movie);

                  return (
                      movie.poster_path && (
                          <div key={id} className="flex-none w-40 bg-black pr-4 rounded shadow-lg">
                              {movie?.poster_path && (
                                  <Link to={`/overview/movie/${movie.id}`}>
                                      <img src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`} alt={movie?.title} className="max-h-[280px] w-auto object-cover rounded" />
                                  </Link>
                              )}
                              <p className="text-center mt-2">{movie?.title}</p>
                          </div>
                      )
                  );
              })}
          </div>

					<MdChevronRight
            onClick={(event) => slideRight('actorMovies', event)}
            style={{ right: `${rightPosition}px` }}
            className='bg-white text-black right-0 rounded-full absolute opacity-50 hover:opacity-100 cursor-pointer z-10 hidden group-hover:block'
            size={40}
          />
				</div>
      </div>
    </div>
		</>
  );
}

export default Person;
